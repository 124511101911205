import { actionCreator } from "../../_functions/actionCreatorFunction";
import { reducerHandler } from "../../_functions/reducerHandler";

// Actions
const allEditorAction = actionCreator('ALL_EDITOR');
const detailEditorAction = actionCreator('DETAIL_EDITOR');
const editEditorAction = actionCreator('EDIT_EDITOR');
const allAioEditedAction = actionCreator('ALL_AIO_EDITED');
const updatePasswordAction = actionCreator('UPDATE_PASSWORD');
const deleteEditorAction = actionCreator('DELETE_EDITOR');
const addEditorAction = actionCreator('ADD_EDITOR');
const exportEditorAction = actionCreator('EXPORT_EDITOR');
const getCitiesAction = actionCreator('GET_CITIES');
const clearDataAction = actionCreator('CLEAR_DATA');
const filterEditorAction = actionCreator('FILTER_EDITOR');
const editGarageAction = actionCreator('EDIT_GARAGE');

export {
    allEditorAction, allAioEditedAction, detailEditorAction, editEditorAction, updatePasswordAction, deleteEditorAction, addEditorAction,
    exportEditorAction, getCitiesAction, clearDataAction, filterEditorAction, editGarageAction
}
// Initial Async State
const initialAsyncState = {
    isLoading: false,
    loaded: false,
    data: null,
    error: null,
};

// Initial State
const initialState = {
    editors: initialAsyncState,
    aioEdited: initialAsyncState,
    detailEditor: initialAsyncState,
    exportedData: initialAsyncState,
    updatedPassword: initialAsyncState,
    successAlert: initialAsyncState,
    deleteEditor: initialAsyncState,
    addEditor: initialAsyncState,
    cities: initialAsyncState,
    filter: initialAsyncState,
    garageEdited: initialAsyncState
};

// Editor Reducer
const editorReducer = (state = initialState, action) => {
    switch (action.type) {
        case allEditorAction.REQUEST:
        case allEditorAction.SUCCESS:
        case allEditorAction.FAILURE:
            return {
                ...state,
                editors: reducerHandler(state.editors, action, allEditorAction),
            };
        case detailEditorAction.REQUEST:
        case detailEditorAction.SUCCESS:
        case detailEditorAction.FAILURE:
            return {
                ...state,
                detailEditor: reducerHandler(state.detailEditor, action, detailEditorAction),
            };
        case clearDataAction.REQUEST:
        case clearDataAction.SUCCESS:
        case clearDataAction.FAILURE:
            return {
                ...state,
                detailEditor: reducerHandler(state.detailEditor, action, clearDataAction),
            };
        case editEditorAction.REQUEST:
        case editEditorAction.SUCCESS:
        case editEditorAction.FAILURE:
            return {
                ...state,
                successAlert: reducerHandler(state.successAlert, action, editEditorAction),
            };
        case allAioEditedAction.REQUEST:
        case allAioEditedAction.SUCCESS:
        case allAioEditedAction.FAILURE:
            return {
                ...state,
                aioEdited: reducerHandler(state.aioEdited, action, allAioEditedAction),
            };
        case updatePasswordAction.REQUEST:
        case updatePasswordAction.SUCCESS:
        case updatePasswordAction.FAILURE:
            return {
                ...state,
                successAlert: reducerHandler(state.successAlert, action, updatePasswordAction),
            };
        case addEditorAction.REQUEST:
        case addEditorAction.SUCCESS:
        case addEditorAction.FAILURE:
            return {
                ...state,
                addEditor: reducerHandler(state.addEditor, action, addEditorAction),
            };
        case exportEditorAction.REQUEST:
        case exportEditorAction.SUCCESS:
        case exportEditorAction.FAILURE:
            return {
                ...state,
                exportedData: reducerHandler(state.exportedData, action, exportEditorAction),
            };
        case getCitiesAction.REQUEST:
        case getCitiesAction.SUCCESS:
        case getCitiesAction.FAILURE:
            return {
                ...state,
                cities: reducerHandler(state.cities, action, getCitiesAction),
            };
        case filterEditorAction.REQUEST:
        case filterEditorAction.SUCCESS:
        case filterEditorAction.FAILURE:
            return {
                ...state,
                filter: reducerHandler(state.filter, action, filterEditorAction),
            };
        case deleteEditorAction.REQUEST:
        case deleteEditorAction.SUCCESS:
        case deleteEditorAction.FAILURE:
            return {
                ...state,
                deleteEditor: reducerHandler(state.deleteEditor, action, deleteEditorAction),
            };
        case editGarageAction.REQUEST:
        case editGarageAction.SUCCESS:
        case editGarageAction.FAILURE:
            return {
                ...state,
                deleteEditor: reducerHandler(state.garageEdited, action, editGarageAction),
            };
        default:
            return state;
    }
};
export default editorReducer;
