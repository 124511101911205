import { combineReducers } from 'redux';
import { authReducer } from './authentication/actions';
import ChangeLayoutMode from './themeLayout/reducers';
import { adminReducer } from './user/admin/reducers';
import { alertReducer } from './alert/reducers'
import { generalConditionReducer } from './legal-text/general-conditions/reducers';
import { legalMentionReducer } from './legal-text/legal-mentions/reducers';
import { usageNoticeReducer } from './legal-text/usage-notices/reducers';
import { dahsboardReducer } from './dashbord/reducers'
import { fileReducer } from './file/reducers';
import { CitiesReducer } from './cities/reducers';
import { aioReducer } from './aio/actions';
import operatorReducer from './user/operator/actions';
import editorReducer from './user/monteur/actions';
import clientReducer from './user/client/actions';

const rootReducers = combineReducers({
  auth: authReducer,
  ChangeLayoutMode,
  operator: operatorReducer,
  editor: editorReducer,
  client: clientReducer,
  admin: adminReducer,
  AIO: aioReducer,
  alert: alertReducer,
  condition: generalConditionReducer,
  mention: legalMentionReducer,
  notice: usageNoticeReducer,
  dashboard: dahsboardReducer,
  file: fileReducer,
  cities: CitiesReducer
});

export default rootReducers;
