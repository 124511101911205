import { actionCreator } from "../_functions/actionCreatorFunction";
import { reducerHandler } from "../_functions/reducerHandler";

// Actions and Types
const allAioActif = actionCreator('ALL_AIO_ACTIF_SUCCESS');
const allAioInactif = actionCreator('ALL_AIO_INACTIF_SUCCESS');
const singleAio = actionCreator('GET_AIO_DETAIL');
const aioAdded = actionCreator('ADD_AIO_SUCCESS');
const aioActivated = actionCreator('ACTIVATE_AIO_SUCCESS');
const onlyActiveAios = actionCreator('ONLY_ACTIVE_AIOS');
const onlyMaintenanceAios = actionCreator('ONLY_MAINTENANCE_AIOS');
const onlyInStockAios = actionCreator('ONLY_IN_STOCK_AIOS');
const onlyAssignedAios = actionCreator('ONLY_ASSIGNED_AIOS');
const twentyInStockAios = actionCreator('TWENTY_IN_STOCK_AIOS');
const onlyBrokenDownAios = actionCreator('ONLY_BROKEN_DOWN_AIOS');
const aioExported = actionCreator('EXPORT_AIO_SUCCESS');
const activeAioExported = actionCreator('EXPORT_ACTIVE_AIO_SUCCESS');
const pollutionData = actionCreator('POLLUTION_DATA');
const mobilePollutionData = actionCreator('MOBILE_POLLUTION_DATA');
const mobileRefuelingData = actionCreator('MOBILE_REFUELING_DATA');
const refuelingData = actionCreator('REFUELING_DATA');
const filterAio = actionCreator('FILTER_AIO_SUCCESS');
const filterAioActive = actionCreator('FILTER_AIO_ACTIVE_SUCCESS');
const statData = actionCreator('STAT_DATA');
const singleAioData = actionCreator('SINGLE_AIO_DATA_SUCCESS');
const contactEditor = actionCreator('CONTACT_EDITOR_SUCCESS');
const aioDeleted = actionCreator('DELETE_AIO');
const aioDissociated = actionCreator('DISSOCIER_AIO');
const activerAio = actionCreator('ACTIVER_AIO');
const aioUnactive = actionCreator('DESACTIVER_AIO');
const aioEdited = actionCreator('EDIT_AIO');
const clearData = actionCreator('CLEAR_DATA_AIO');
const cardDataDeleted = actionCreator('DELETE_CARD_DATA_AIO');
const exportDataAio = actionCreator('EXPORT_DATA_AIO_SUCCESS');
const aioImage = actionCreator('AIO_IMAGE_SUCCESS');
const aioPdf = actionCreator('AIO_PDF_SUCCESS');
const aioPv = actionCreator('AIO_PV_SUCCESS');
const aioImportAction = actionCreator('AIO_IMPORT');

// Initial State
const initialAsyncState = {
    isLoading: false,
    loaded: false,
    data: null,
    error: null
};

const initialState = {
    allAioActif: initialAsyncState,
    allAioInactif: initialAsyncState,
    singleAio: initialAsyncState,
    aioAdded: initialAsyncState,
    aioActivated: initialAsyncState,
    onlyActiveAios: initialAsyncState,
    onlyMaintenanceAios: initialAsyncState,
    onlyInStockAios: initialAsyncState,
    onlyAssignedAios: initialAsyncState,
    twentyInStockAios: initialAsyncState,
    onlyBrokenDownAios: initialAsyncState,
    aioExported: initialAsyncState,
    activeAioExported: initialAsyncState,
    pollutionData: initialAsyncState,
    mobilePollutionData: initialAsyncState,
    mobileRefuelingData: initialAsyncState,
    refuelingData: initialAsyncState,
    filterAio: initialAsyncState,
    filterAioActive: initialAsyncState,
    statData: initialAsyncState,
    singleAioData: initialAsyncState,
    contactEditor: initialAsyncState,
    aioDeleted: initialAsyncState,
    aioDissociated: initialAsyncState,
    activerAio: initialAsyncState,
    aioUnactive: initialAsyncState,
    aioEdited: initialAsyncState,
    clearData: initialAsyncState,
    cardDataDeleted: initialAsyncState,
    exportDataAio: initialAsyncState,
    aioImage: initialAsyncState,
    aioPdf: initialAsyncState,
    aioPv: initialAsyncState,
    aioImport: initialAsyncState
};

// Reducer
const aioReducer = (state = initialState, action) => {
    switch (action.type) {
        case allAioActif.REQUEST:
        case allAioActif.SUCCESS:
        case allAioActif.FAILURE:
            return {
                ...state,
                allAioActif: reducerHandler(state.allAioActif, action, allAioActif)
            };
        case allAioInactif.REQUEST:
        case allAioInactif.SUCCESS:
        case allAioInactif.FAILURE:
            return {
                ...state,
                allAioInactif: reducerHandler(state.allAioInactif, action, allAioInactif)
            };
        case singleAio.REQUEST:
        case singleAio.SUCCESS:
        case singleAio.FAILURE:
            return {
                ...state,
                singleAio: reducerHandler(state.singleAio, action, singleAio)
            };
        case aioAdded.REQUEST:
        case aioAdded.SUCCESS:
        case aioAdded.FAILURE:
            return {
                ...state,
                aioAdded: reducerHandler(state.aioAdded, action, aioAdded)
            };
        case aioActivated.REQUEST:
        case aioActivated.SUCCESS:
        case aioActivated.FAILURE:
            return {
                ...state,
                aioActivated: reducerHandler(state.aioActivated, action, aioActivated)
            };
        case onlyActiveAios.REQUEST:
        case onlyActiveAios.SUCCESS:
        case onlyActiveAios.FAILURE:
            return {
                ...state,
                onlyActiveAios: reducerHandler(state.onlyActiveAios, action, onlyActiveAios)
            };
        case onlyMaintenanceAios.REQUEST:
        case onlyMaintenanceAios.SUCCESS:
        case onlyMaintenanceAios.FAILURE:
            return {
                ...state,
                onlyMaintenanceAios: reducerHandler(state.onlyMaintenanceAios, action, onlyMaintenanceAios)
            };
        case onlyInStockAios.REQUEST:
        case onlyInStockAios.SUCCESS:
        case onlyInStockAios.FAILURE:
            return {
                ...state,
                onlyInStockAios: reducerHandler(state.onlyInStockAios, action, onlyInStockAios)
            };
        case onlyAssignedAios.REQUEST:
        case onlyAssignedAios.SUCCESS:
        case onlyAssignedAios.FAILURE:
            return {
                ...state,
                onlyAssignedAios: reducerHandler(state.onlyAssignedAios, action, onlyAssignedAios)
            };
        case twentyInStockAios.REQUEST:
        case twentyInStockAios.SUCCESS:
        case twentyInStockAios.FAILURE:
            return {
                ...state,
                twentyInStockAios: reducerHandler(state.twentyInStockAios, action, twentyInStockAios)
            };
        case onlyBrokenDownAios.REQUEST:
        case onlyBrokenDownAios.SUCCESS:
        case onlyBrokenDownAios.FAILURE:
            return {
                ...state,
                onlyBrokenDownAios: reducerHandler(state.onlyBrokenDownAios, action, onlyBrokenDownAios)
            };
        case aioExported.REQUEST:
        case aioExported.SUCCESS:
        case aioExported.FAILURE:
            return {
                ...state,
                aioExported: reducerHandler(state.aioExported, action, aioExported)
            };
        case activeAioExported.REQUEST:
        case activeAioExported.SUCCESS:
        case activeAioExported.FAILURE:
            return {
                ...state,
                activeAioExported: reducerHandler(state.activeAioExported, action, activeAioExported)
            };
        case pollutionData.REQUEST:
        case pollutionData.SUCCESS:
        case pollutionData.FAILURE:
            return {
                ...state,
                pollutionData: reducerHandler(state.pollutionData, action, pollutionData)
            };
        case mobilePollutionData.REQUEST:
        case mobilePollutionData.SUCCESS:
        case mobilePollutionData.FAILURE:
            return {
                ...state,
                mobilePollutionData: reducerHandler(state.mobilePollutionData, action, mobilePollutionData)
            };
        case mobileRefuelingData.REQUEST:
        case mobileRefuelingData.SUCCESS:
        case mobileRefuelingData.FAILURE:
            return {
                ...state,
                mobileRefuelingData: reducerHandler(state.mobileRefuelingData, action, mobileRefuelingData)
            };
        case refuelingData.REQUEST:
        case refuelingData.SUCCESS:
        case refuelingData.FAILURE:
            return {
                ...state,
                refuelingData: reducerHandler(state.refuelingData, action, refuelingData)
            };
        case filterAio.REQUEST:
        case filterAio.SUCCESS:
        case filterAio.FAILURE:
            return {
                ...state,
                filterAio: reducerHandler(state.filterAio, action, filterAio)
            };
        case filterAioActive.REQUEST:
        case filterAioActive.SUCCESS:
        case filterAioActive.FAILURE:
            return {
                ...state,
                filterAioActive: reducerHandler(state.filterAioActive, action, filterAioActive)
            };
        case statData.REQUEST:
        case statData.SUCCESS:
        case statData.FAILURE:
            return {
                ...state,
                statData: reducerHandler(state.statData, action, statData)
            };
        case singleAioData.REQUEST:
        case singleAioData.SUCCESS:
        case singleAioData.FAILURE:
            return {
                ...state,
                singleAioData: reducerHandler(state.singleAioData, action, singleAioData)
            };
        case contactEditor.REQUEST:
        case contactEditor.SUCCESS:
        case contactEditor.FAILURE:
            return {
                ...state,
                contactEditor: reducerHandler(state.contactEditor, action, contactEditor)
            };
        case aioDeleted.REQUEST:
        case aioDeleted.SUCCESS:
        case aioDeleted.FAILURE:
            return {
                ...state,
                aioDeleted: reducerHandler(state.aioDeleted, action, aioDeleted)
            };
        case aioDissociated.REQUEST:
        case aioDissociated.SUCCESS:
        case aioDissociated.FAILURE:
            return {
                ...state,
                aioDissociated: reducerHandler(state.aioDissociated, action, aioDissociated)
            };
        case activerAio.REQUEST:
        case activerAio.SUCCESS:
        case activerAio.FAILURE:
            return {
                ...state,
                activerAio: reducerHandler(state.activerAio, action, activerAio)
            };
        case aioUnactive.REQUEST:
        case aioUnactive.SUCCESS:
        case aioUnactive.FAILURE:
            return {
                ...state,
                aioUnactive: reducerHandler(state.aioUnactive, action, aioUnactive)
            };
        case aioEdited.REQUEST:
        case aioEdited.SUCCESS:
        case aioEdited.FAILURE:
            return {
                ...state,
                aioEdited: reducerHandler(state.aioEdited, action, aioEdited)
            };
        case clearData.REQUEST:
        case clearData.SUCCESS:
        case clearData.FAILURE:
            return {
                ...state,
                clearData: reducerHandler(state.clearData, action, clearData)
            };
        case cardDataDeleted.REQUEST:
        case cardDataDeleted.SUCCESS:
        case cardDataDeleted.FAILURE:
            return {
                ...state,
                cardDataDeleted: reducerHandler(state.cardDataDeleted, action, cardDataDeleted)
            };
        case exportDataAio.REQUEST:
        case exportDataAio.SUCCESS:
        case exportDataAio.FAILURE:
            return {
                ...state,
                exportDataAio: reducerHandler(state.exportDataAio, action, exportDataAio)
            };
        case aioImage.REQUEST:
        case aioImage.SUCCESS:
        case aioImage.FAILURE:
            return {
                ...state,
                aioImage: reducerHandler(state.aioImage, action, aioImage)
            };
        case aioPdf.REQUEST:
        case aioPdf.SUCCESS:
        case aioPdf.FAILURE:
            return {
                ...state,
                aioPdf: reducerHandler(state.aioPdf, action, aioPdf)
            };
        case aioPv.REQUEST:
        case aioPv.SUCCESS:
        case aioPv.FAILURE:
            return {
                ...state,
                aioPv: reducerHandler(state.aioPv, action, aioPv)
            };
        case aioImportAction.REQUEST:
        case aioImportAction.SUCCESS:
        case aioImportAction.FAILURE:
            return {
                ...state,
                aioImport: reducerHandler(state.aioImport, action, aioImportAction)
            };
        default:
            return state;
    }
};

export {
    aioReducer,
    allAioActif,
    allAioInactif,
    singleAio,
    aioAdded,
    aioActivated,
    onlyActiveAios,
    onlyMaintenanceAios,
    onlyInStockAios,
    onlyAssignedAios,
    twentyInStockAios,
    onlyBrokenDownAios,
    aioExported,
    activeAioExported,
    pollutionData,
    mobilePollutionData,
    mobileRefuelingData,
    refuelingData,
    filterAio,
    filterAioActive,
    statData,
    singleAioData,
    contactEditor,
    aioDeleted,
    aioDissociated,
    activerAio,
    aioUnactive,
    aioEdited,
    clearData,
    cardDataDeleted,
    exportDataAio,
    aioImage,
    aioPdf,
    aioPv,
    aioImportAction
};
