import actions from "./actions";

const { ALL_ADMINS_SUCCESS } = actions;

/* admin state */
const initialStateALL = {
    admins: [],
}

/* admin reducer */
const adminReducer = (state = initialStateALL, action) => {
    const { type, data } = action;
    switch (type) {
        case ALL_ADMINS_SUCCESS:
            return {
                ...state,
                admins: data,
            };
        default:
            return state
    };
};

export { adminReducer };