import Cookies from 'js-cookie';
import { getLogin, getLogout } from './actions';
import { API_HOST, API } from '../../config/api'
import axios from 'axios';
import { setRefreshToken, setToken } from '../../services/authenticationService'
import actionsAlert from "../alert/actions"

const { alertError } = actionsAlert;

/* login */
const login = (values, history, stay) => {
  return async dispatch => {
    const URL = API_HOST + API.auth.login
    getLogin.request()
    axios.post(URL, values).then(
      (res) => {
        setToken(res.data.token)
        setRefreshToken(res.data.refresh_token)
        if (stay === true) {
          Cookies.set('logedIn', true, { expires: 365 });
          Cookies.set('token', res.data.token, { expires: 365 });
        } else {
          Cookies.set('logedIn', true);
          Cookies.set('token', res.data.token);
        }
        history.push('/admin');
        return dispatch(getLogin.success(Cookies.get('token')));
      },
      (err) => {
        dispatch(getLogin.failure(err))
        if (err.response.data.message)
          dispatch(alertError({ msg: err.response.data.message, type: 'error' }));
        else
          dispatch(alertError({ msg: 'Erreur d\'authentification', type: 'error' }));

      }
    )
  };
};

/* logout */
const logOut = () => {
  return async dispatch => {
    try {
      dispatch(getLogout.request());
      Cookies.remove('logedIn');
      Cookies.remove('token');
      dispatch(getLogin.success(null));
    } catch (err) {
      dispatch(getLogout.failure(true));
     }
  };
};

export { login, logOut };
