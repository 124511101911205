import { Col, Row } from 'antd';
import React from 'react';
import { Aside, Content } from './overview/style';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <Row>
        <Col xxl={8} xl={9} lg={12} md={24} xs={24}>
          <Aside>
            <div className="auth-side-content">
              <Content>
                {/* needed to add style in balise, no other solution was found to force the style */}
                <img style={{ width: '250px' }} src={require('../../static/img/logoWhite.svg')} alt="logikko-logo" />
              </Content>
            </div>
          </Aside>
        </Col>
        <Col xxl={16} xl={14} lg={12} md={24} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
