const actions = {
    EDIT_GENERAL_CONDITION_SUCCESS: 'EDIT_GENERAL_CONDITION_SUCCESS',
    GET_GENERAL_CONDITION_SUCCESS: 'GET_GENERAL_CONDITION_SUCCESS',

    /* edit legal mentions */
    editGeneralConditionSucess: data => {
        return {
            type: actions.EDIT_GENERAL_CONDITION_SUCCESS,
            data,
        }
    },

    /* get legal mentions */
    getGeneralConditionSucess: data => {
        return {
            type: actions.GET_GENERAL_CONDITION_SUCCESS,
            data,
        }
    },
}

export default actions;