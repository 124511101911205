import actions from './actions';

const { EDIT_USAGE_NOTICE_SUCCESS, GET_USAGE_NOTICE_SUCCESS } = actions;

/* usage Notice state */
const initialState = {
    getNotice: {} ,
    editNotice: []
}

/* usage Notice reducer */
const usageNoticeReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_USAGE_NOTICE_SUCCESS:
            return { ...state, getNotice: data };
        case EDIT_USAGE_NOTICE_SUCCESS:
            return { ...state, editNotice: data };
        default:
            return state;
    }
};
export { usageNoticeReducer };