import actions from './actions';

const { EDIT_GENERAL_CONDITION_SUCCESS, GET_GENERAL_CONDITION_SUCCESS } = actions;

/* General condition state */
const initialState = {
    getCondition: {} ,
    editCondition: []
}

/* General condition reducer */
const generalConditionReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_GENERAL_CONDITION_SUCCESS:
            return { ...state, getCondition: data };
        case EDIT_GENERAL_CONDITION_SUCCESS:
            return { ...state, editCondition: data };
        default:
            return state;
    }
};
export { generalConditionReducer };