import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';
import Loader from '../../components/loader';

const Dashboard = lazy(() => import('../../container/dashboard'));
const AIOActif = lazy(() => import('../../container/aio/aio-actif'));
const AIOInactif = lazy(() => import('../../container/aio/aio-inactif'));
const Exploitants = lazy(() => import('../../container/users/exploitants'));
const AIODetail = lazy(() => import('../../container/aio/aio-detail'));
const Admins = lazy(() => import('../../container/users/admins'));
const Conducteurs = lazy(() => import('../../container/users/conducteurs'));
const Monteurs = lazy(() => import('../../container/users/assemblers/assembler-list'));
const Conditions = lazy(() => import('../../container/legal-text/general-conditions/condition'))
const Legal = lazy(() => import('../../container/legal-text/legal-mentions/mention'))
const Notices = lazy(() => import('../../container/legal-text/usage-notices/notices'))
const DetailMonteur = lazy(() => import('../../container/users/assemblers/assembler-detail'))
const EditMention = lazy(() => import('../../container/legal-text/legal-mentions/edit-mention'))
const EditCondition = lazy(() => import('../../container/legal-text/general-conditions/edit-condition'))
const EditNotice = lazy(() => import('../../container/legal-text/usage-notices/edit-notices'))
const DetailExploitant = lazy(() => import('../../container/users/exploitants/exploitant-detail'));


const Admin = () => {
  const { path } = useRouteMatch();
  const fallback = () => {
    return (
      <div className="spin">
        <Spin />
      </div>
    )
  }

  return (
    <>
      <Loader />
      <Switch>
        <Suspense fallback={fallback()}>
          <Route exact path={path} component={Dashboard} />
          <Route exact path={`${path}/AIO/actif`} component={AIOActif} />
          <Route exact path={`${path}/AIO/inactif`} component={AIOInactif} />
          <Route path={`${path}/AIO/detail/:id`} component={AIODetail} />
          <Route exact path={`${path}/users/conducteurs`} component={Conducteurs} />
          <Route exact path={`${path}/users/exploitants`} component={Exploitants} />
          <Route exact path={`${path}/users/monteurs`} component={Monteurs} />
          <Route exact path={`${path}/users/admins`} component={Admins} />
          <Route path={`${path}/users/detail/:id`} component={DetailMonteur} />
          <Route path={`${path}/notices/condition-generale`} component={Conditions} />
          <Route path={`${path}/notices/mentions-legales`} component={Legal} />
          <Route path={`${path}/notices/notices-utilisation`} component={Notices} />
          <Route path={`${path}/notices/modifier-mentions`} component={EditMention} />
          <Route path={`${path}/notices/modifier-conditions`} component={EditCondition} />
          <Route path={`${path}/notices/modifier-notices`} component={EditNotice} />
          <Route path={`${path}/users/exploitants/detail/:id`} component={DetailExploitant} />
        </Suspense>
      </Switch>
    </>
  );
};

export default withAdminLayout(Admin);
