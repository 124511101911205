import actions from './actions';

const { FILTER_CITIES, ALL_DEPARTMENTS, ALL_DEPARTMENT_CITIES, GET_CITY, FILTER_DEPARTMENTS, CLEAR_DATA_CITY } = actions;

/* aio state */
const initialState = {
    filteredCities: [],
    filteredDepartments: [],
    departments: [],
    cities: [],
    city: [],
}

/* aio reducer */
const CitiesReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case FILTER_CITIES:
            return { ...state, filteredCities: data };
        case FILTER_DEPARTMENTS:
            return { ...state, filteredDepartments: data };
        case ALL_DEPARTMENTS:
            return { ...state, departments: data };
        case ALL_DEPARTMENT_CITIES:
            return { ...state, cities: data };
        case GET_CITY:
            return { ...state, city: data };
        case CLEAR_DATA_CITY:
            return { ...state, city: [], cities: [], departments: [] };
        default:
            return state;
    }
};
export { CitiesReducer };