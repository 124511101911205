import { actionCreator } from "../../_functions/actionCreatorFunction";
import { reducerHandler } from "../../_functions/reducerHandler";

// Actions
const allOperatorAction = actionCreator('ALL_OPERATOR');
const editOperatorAction = actionCreator('EDIT_OPERATOR');
const detailOperatorAction = actionCreator('DETAIL_OPERATOR');
const addOperatorAction = actionCreator('ADD_OPERATOR');
const exportOperatorAction = actionCreator('EXPORT_OPERATOR');
const allAioOperatorAction = actionCreator('ALL_AIO_OPERATOR');
const deleteOperatorAction = actionCreator('DELETE_OPERATOR');
const filterOperatorAction = actionCreator('FILTER_OPERATOR');
const operatorVehicleAction = actionCreator('GET_OPERATOR_VEHICLE');
const getOperatorInactiveVehicleAction = actionCreator('GET_OPERATOR_INACTIVE_VEHICLE');
const filterOperatorInactiveVehicleAction = actionCreator('FILTER_OPERATOR_INACTIVE_VEHICLE');
const clearDataAction = actionCreator('CLEAR_DATA');
const deleteVehicleAction = actionCreator('DELETE_VEHICLE');

export {
    allOperatorAction, editOperatorAction, detailOperatorAction, addOperatorAction, exportOperatorAction, allAioOperatorAction, deleteOperatorAction, filterOperatorAction, operatorVehicleAction, getOperatorInactiveVehicleAction,
    filterOperatorInactiveVehicleAction, clearDataAction, deleteVehicleAction
};

// Initial Async State
const initialAsyncState = {
    isLoading: false,
    loaded: false,
    data: null,
    error: null,
};

// Initial State
const initialState = {
    operators: initialAsyncState,
    detailOperator: initialAsyncState,
    vehicles: initialAsyncState,
    inactiveVehicles: initialAsyncState,
    filterInactiveVehicles: initialAsyncState,
    filter: initialAsyncState,
    editSuccess: initialAsyncState,
    operatorAdded: initialAsyncState,
    deleteOperator: initialAsyncState,
    exportedData: initialAsyncState,
    aioOperator: initialAsyncState,
    deleteVehicle: initialAsyncState,
};

const operatorReducer = (state = initialState, action) => {
    switch (action.type) {
        case allOperatorAction.REQUEST:
        case allOperatorAction.SUCCESS:
        case allOperatorAction.FAILURE:
            return {
                ...state,
                operators: reducerHandler(state.operators, action, allOperatorAction),
            };
        case detailOperatorAction.REQUEST:
        case detailOperatorAction.SUCCESS:
        case detailOperatorAction.FAILURE:
            return {
                ...state,
                detailOperator: reducerHandler(state.detailOperator, action, detailOperatorAction),
            };
        case operatorVehicleAction.REQUEST:
        case operatorVehicleAction.SUCCESS:
        case operatorVehicleAction.FAILURE:
            return {
                ...state,
                vehicles: reducerHandler(state.vehicles, action, operatorVehicleAction),
            };
        case getOperatorInactiveVehicleAction.REQUEST:
        case getOperatorInactiveVehicleAction.SUCCESS:
        case getOperatorInactiveVehicleAction.FAILURE:
            return {
                ...state,
                inactiveVehicles: reducerHandler(state.inactiveVehicles, action, getOperatorInactiveVehicleAction),
            };
        case filterOperatorInactiveVehicleAction.REQUEST:
        case filterOperatorInactiveVehicleAction.SUCCESS:
        case filterOperatorInactiveVehicleAction.FAILURE:
            return {
                ...state,
                inactiveVehicles: reducerHandler(state.inactiveVehicles, action, filterOperatorInactiveVehicleAction),
            };
        case filterOperatorAction.REQUEST:
        case filterOperatorAction.SUCCESS:
        case filterOperatorAction.FAILURE:
            return {
                ...state,
                filter: reducerHandler(state.filter, action, filterOperatorAction),
            };
        case editOperatorAction.REQUEST:
        case editOperatorAction.SUCCESS:
        case editOperatorAction.FAILURE:
            return {
                ...state,
                editSuccess: reducerHandler(state.editSuccess, action, editOperatorAction),
            };
        case addOperatorAction.REQUEST:
        case addOperatorAction.SUCCESS:
        case addOperatorAction.FAILURE:
            return {
                ...state,
                operatorAdded: reducerHandler(state.operatorAdded, action, addOperatorAction),
            };
        case clearDataAction.REQUEST:
        case clearDataAction.SUCCESS:
        case clearDataAction.FAILURE:
            return {
                ...state,
                detailOperator: reducerHandler(state.detailOperator, action, clearDataAction),
            };
        case exportOperatorAction.REQUEST:
        case exportOperatorAction.SUCCESS:
        case exportOperatorAction.FAILURE:
            return {
                ...state,
                exportedData: reducerHandler(state.exportedData, action, exportOperatorAction),
            };
        case allAioOperatorAction.REQUEST:
        case allAioOperatorAction.SUCCESS:
        case allAioOperatorAction.FAILURE:
            return {
                ...state,
                aioOperator: reducerHandler(state.aioOperator, action, allAioOperatorAction),
            };
        case deleteOperatorAction.REQUEST:
        case deleteOperatorAction.SUCCESS:
        case deleteOperatorAction.FAILURE:
            return {
                ...state,
                deleteOperator: reducerHandler(state.deleteOperator, action, deleteOperatorAction),
            };
        case deleteVehicleAction.REQUEST:
        case deleteVehicleAction.SUCCESS:
        case deleteVehicleAction.FAILURE:
            return {
                ...state,
                deleteVehicle: reducerHandler(state.deleteVehicle, action, deleteVehicleAction),
            };
        default:
            return state;
    }
};

export default operatorReducer;
