import actions from './actions';

const { EDIT_LEGAL_MENTION_SUCCESS, GET_LEGAL_MENTION_SUCCESS } = actions;

/* Legal Mention state */
const initialState = {
    getMention: {} ,
    editMention: []
}

/* Legal Mention reducer */
const legalMentionReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_LEGAL_MENTION_SUCCESS:
            return { ...state, getMention: data };
        case EDIT_LEGAL_MENTION_SUCCESS:
            return { ...state, editMention: data };
        default:
            return state;
    }
};
export { legalMentionReducer };