import Cookies from 'js-cookie';
import { actionCreator } from "../_functions/actionCreatorFunction"
import { reducerHandler } from "../_functions/reducerHandler"

// Actions and Types
const getLogin = actionCreator('GET_LOGIN')
const getLogout = actionCreator('GET_LOGOUT')

// Initial State
const initialAsyncState = {
    isLoading: false,
    loaded: false,
    data: null,
    error: null
}

// Initial Reducer State
const initialState = {
    login: {...initialAsyncState,
        data: Cookies.get('token') || null, // Set login.data to Cookies.get('token') or null if it doesn't exist
    },
    logout: initialAsyncState
}

// Reducer
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case getLogin.REQUEST:
        case getLogin.SUCCESS:
        case getLogin.FAILURE:
            return {
                ...state,
                login: reducerHandler(state.login, action, getLogin)
            }
        case getLogout.REQUEST:
        case getLogout.SUCCESS:
        case getLogout.FAILURE:
            return {
                ...state,
                logout: reducerHandler(state.logout, action, getLogout)
            }
        default:
            return state
    }
}
export { authReducer }
export { getLogin, getLogout }