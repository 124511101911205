import { actionCreator } from "../../_functions/actionCreatorFunction";
import { reducerHandler } from "../../_functions/reducerHandler";

// Actions
const allClientsAction = actionCreator('ALL_CLIENTS');
const exportClientAction = actionCreator('EXPORT_CLIENT');
const addUserAction = actionCreator('ADD_USER');
const filterAction = actionCreator('FILTER_USER');
export { allClientsAction, exportClientAction, addUserAction, filterAction }
// Initial Async State
const initialAsyncState = {
    isLoading: false,
    loaded: false,
    data: null,
    error: null,
};

// Initial State
const initialState = {
    clients: initialAsyncState,
    exportedData: initialAsyncState,
    filter: initialAsyncState,
    userAdded: initialAsyncState,
};

// Client Reducer
const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case allClientsAction.REQUEST:
        case allClientsAction.SUCCESS:
        case allClientsAction.FAILURE:
            return {
                ...state,
                clients: reducerHandler(state.clients, action, allClientsAction),
            };
        case exportClientAction.REQUEST:
        case exportClientAction.SUCCESS:
        case exportClientAction.FAILURE:
            return {
                ...state,
                exportedData: reducerHandler(state.exportedData, action, exportClientAction),
            };
        case addUserAction.REQUEST:
        case addUserAction.SUCCESS:
        case addUserAction.FAILURE:
            return {
                ...state,
                userAdded: reducerHandler(state.userAdded, action, addUserAction),
            };
        case filterAction.REQUEST:
        case filterAction.SUCCESS:
        case filterAction.FAILURE:
            return {
                ...state,
                filter: reducerHandler(state.filter, action, filterAction),
            };
        default:
            return state;
    }
};

export default clientReducer;
