import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

/* set token */
export const setToken = (token) => {
    localStorage.setItem("token", token);
}

/* set refresh token */
export const setRefreshToken = (token) => {
    localStorage.setItem("refreshToken", token);
}

/* get token */
export const getToken = () => {
    const token = localStorage.getItem("token") || null;
    return token
}

/* get refresh token */
export const getRefreshToken = () => {
    const token = localStorage.getItem("refreshToken") || null;
    return token
}

/* remove token */
export const removeToken = () => {
    localStorage.removeItem("token");
}

/* get Current Current User */
export const getUser = () => {
    const token = localStorage.getItem("token");
    return token ? jwtDecode(token) : null;
}

/* get Id Current User */
export const getUserId = () => {
    const user = getUser();
    return user?.id;
}

/* get Roles Current User */
export const getUserRole = () => {
    const user = getUser();
    return user?.roles;
}

/* get if token is valid */
export const getValidationToken = () => {
    const user = getUser();
    if(new Date() > new Date(user.exp * 1000)){
        Cookies.remove('logedIn')
        Cookies.remove('token')
    }
}

/*  User is Admin */
export const isAdmin = () => {
    const Roles = getUserRole();
    return Roles?.includes('ROLE_ADMIN') ? true : Cookies.remove('logedIn');
}

export const getTokenFromCookies = () => {
    return Cookies.get('token')
}
