const actions = {
    NEWS_BLOCK_SUCCESS: 'NEWS_BLOCK_SUCCESS',
    RECENT_EDITORS_SUCCESS: 'RECENT_EDITORS_SUCCESS',

    /* new block */
    newBlockSuccess: data => {
        return {
            type: actions.NEWS_BLOCK_SUCCESS,
            data,
        }
    },

    /* recent editors */
    recentEditorsSuccess: data => {
        return {
            type: actions.RECENT_EDITORS_SUCCESS,
            data,
        }
    },
}

export default actions;